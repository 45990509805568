.case-title {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-title__content {
        grid-column: 2/span 10;

        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 700;
        }
    }
}