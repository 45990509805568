.case-website-screenshot-with-text-right {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2.5rem;

    align-items: center;

    .case-website-screenshot-with-text-right__figure {
        grid-column: 1/span 7;
    }

    .case-website-screenshot-with-text-right__content {
        grid-column: 8/span 5;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 600;
        }

        .content-description {
            width: 100%;
        }

        .content-action {
            width: 100%;
            margin-top: 0.5rem;

            a {
                border-radius: 1.25rem;
                border-width: 2px;
                border-style: solid;
                border-color: #2C80FF;

                background-color: #2C80FF;
                color: #fff;
                fill: #fff;

                padding: 0.5rem 1rem
            }

            .action-text {
                text-align: center;
                font-weight: 600;
                color: #fff;
                fill: #fff;
            }
        }
    }
}