.contacts-image-floating-left-description {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto;

  margin-bottom: 60px;

  .showcase {
    padding-top: 70px;
    gap: 0;
    padding-bottom: 120px;
    align-items: start;
    position: relative;

    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;

    .showcase__content {
      grid-column: 1 / span 6;
      order: 2;
      top: -70px;
      z-index: 1;
      border-radius: 20px;
      position: relative;
      grid-row: 1;
      background-color: var(--secondaryColorLight);
      width: 100%;
      padding: 35px 35px 35px 35px;

      .heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 2.8rem;
        margin-bottom: 15px;
        width: 100%;
        color: $primary1;
      }

      .description_input {
        margin-bottom: 15px;
        width: 100%;
        color: $primary1;
        line-height: 2rem;
        font-family: $regular;
      }

      .content-button__inner {
        background-color: $secondary1;
        color: $primary2;
        font-family: $regular;
      }

      .text-primary-color {
        color: $primary2 !important;
        font-weight: 600;
      }
    }

    .showcase__figure {
      grid-column: 5 / span 8;
      order: 1;
      grid-row: 1;

      .content-image {
        overflow: hidden;
        border-radius: 1.5rem;

        img {
          object-fit: cover;
          display: inline-block;
          max-width: 100%;
          vertical-align: top;
        }
      }

      .showcase__cta {
        right: 40px;
        margin: 0;

        width: 100%;
        max-width: 210px;
        bottom: 30px;

        margin: 0 auto;
        z-index: 2;

        gap: 30px;

        flex-direction: column;

        display: flex;
        border-radius: 20px;
        position: absolute !important;
        padding: 35px;
        color: #fcfcfc;
        background-color: var(--primaryColor);

        .heading__cta {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.2rem;
          color: #fcfcfc;
        }
      }
    }
  }
}
