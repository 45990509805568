.about-us-centered-paragraph {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12,1fr);

    .paragraph__content {
        grid-column: 3/span 8;

        display: flex;
        flex-direction: column;

        .text-content {
            width: 100%;
            font-size: 1.25rem;
        }

        .content-action {
            width: 100%;
            font-size: 1.25rem;
        }

        .link-title-input {
            color: #000 !important;
            text-decoration-line: underline;
        }
    }
}