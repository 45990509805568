.case-showcase-featured {
    width: 100%;

    padding: 3.5rem;

    border-radius: 20px;
    background-color: #EFF5FF;

    .case-showcase-featured__container {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 2.5rem;

        align-items: center;

        .container__content {
            grid-column: 1/span 4;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            .content-title {
                width: 100%;
                font-size: 2rem;
                font-weight: 600;
            }

            .content-description {
                width: 100%;
            }

            .content-action {
                width: 100%;
                margin-top: 0.5rem;

                a {
                    border-radius: 1.25rem;
                    border-width: 2px;
                    border-style: solid;
                    border-color: #2C80FF;

                    background-color: #2C80FF;
                    color: #fff;
                    fill: #fff;

                    padding: 0.5rem 1rem
                }

                .action-text {
                    text-align: center;
                    font-weight: 600;
                    color: #fff;
                    fill: #fff;
                }
            }
        }

        .container__figure {
            grid-column: 5/span 8;
        }
    }

}