.about-us-content-heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12,1fr);

    .element__content {
        width: 100%;
        grid-column: 2/span 10;

        display: flex;
        flex-direction: column;

        .content-title {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c80ff;

        }

        .content-heading {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 2.5rem;
            line-height: 1;
            font-weight: 700;

        }

        .content-description {
            width: 100%;
            margin-top: 1.25rem;
            font-size: 1.25rem;
        }
    }

}