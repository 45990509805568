.specializations-blogposts {
  position: relative;
  width: 100%;

  .specializations-blogposts__content {
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    margin-bottom: 50px;

    .inner {
      justify-content: space-between;
      align-items: center;
      display: flex;

      .content-heading {
        position: relative;
        white-space: nowrap;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1;
        font-size: 2.25rem;
      }
    }
  }

  .specializations-blogposts__items {
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;

    max-width: 860px;

    .blogs-carousel {
      position: relative;
      width: calc(50vw + 440px);
      grid-template-columns: auto;
      gap: 35px;

      .viewport {
        left: -10px;
        overflow: hidden;
        position: relative;

        .viewport-slider {
          left: 10px !important;
          height: 100%;
          position: absolute;

          .blogpost-item {
            margin-right: 25px;
            width: 300px;
            padding-bottom: 10px;
            padding-top: 10px;
            // z-index: 1;

            .card__inner {
              font-size: 1.8rem;
              line-height: 3.4rem;
              position: relative;
            }

            .figure {
              margin: 0;
              display: block;
              margin-block-start: 0.25em;
              margin-block-end: 0.25em;
              margin-inline-end: 10px;

              .content-image {
                overflow: hidden;
                border-radius: 1rem;

                img {
                  max-width: 100%;
                  max-height: 27rem;
                }
              }
            }

            .tags {
              z-index: 2;
              gap: 8px;
              flex-wrap: wrap;
              display: flex;
              position: relative;
              text-transform: uppercase;
              font-weight: 700;
              line-height: 2.2rem !important;
              font-size: 10px;
              letter-spacing: 0.7px;
              margin-bottom: 15px;
              margin-top: 20px;

              .tag {
                border-radius: 2px;
                padding-left: 10px;
                padding-right: 10px;

                fill: #fff;
                background-color: #2c80ff;
                color: #fff;
                cursor: pointer;
              }
            }

            .item-heading {
              .figure-heading {
                font-weight: 800;
                font-size: 1.2rem;
                line-height: 1;
                letter-spacing: 0.3px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
