.plugin-highlight-left-image-info {
  position: relative;
  display: flex;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    fill: #2c80ff;
    background-color: #2c80ff;
    color: #fff;
    border-radius: 20px;
    content: '';
  }
  .showcase {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    width: 100%;
    align-items: center;
    grid-gap: 30px;

    .showcase__content {
      grid-column: 9 / span 4;
      order: 1;
      grid-row: 1;
      z-index: 1;

      .heading {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
        width: 100%;
        color: #fff;
      }

      .description {
        word-wrap: break-word;
        width: 100%;
        color: #fff;
      }

      .buttons {
        gap: 15px;
        flex-wrap: wrap;
        display: flex;
        margin-top: 25px;
        flex-direction: row;
        width: 100%;

        .button {
          fill: #fff;
          background-color: #2c80ff;
          border-color: #2c80ff;
          color: #fff;
          border-radius: 20px;
          font-size: 14px;
          gap: 10px;
          width: auto;
          align-items: center;
          display: flex;
          border-style: solid;
          border-width: 2px;
          box-shadow: 0 0 0 0 transparent;
          cursor: pointer;
          flex-wrap: nowrap;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }

    .showcase__figure {
      grid-column: 1 / span 8;
      order: 2;
      grid-row: 1;
      z-index: 1;
    }
  }
}
