.home-services {
  width: 100%;
  display: flex;

  .home-services__content {
    margin-right: 6rem;
    max-width: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .content-title {
      max-width: 240px;
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c80ff;
    }

    .content-description {
      max-width: 240px;
      width: auto !important;
      margin-top: 8px;
      font-size: 2.5rem;
      line-height: 1;
      font-weight: 700;
    }

    .content-action {
      margin-top: 0.5rem;
    }

    .content-image {
      margin-top: 2.75rem;
      img {
        border-radius: 1.25rem;
      }
    }
  }

  .home-services__list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    ul {
      list-style-type: none;
    }

    .item {
      display: flex;
      flex-direction: column;

      .item-title {
        margin-top: 1.5rem;
        font-size: 1.75rem;
        font-weight: 500;
      }

      .item-description {
        margin-top: 0.25rem;
        display: block;
        font-size: 1.25rem;
      }

      .svg-icon {
        height: 35px;
        width: 35px;
      }

      .item-action {
        margin-top: 0.75rem;
        color: #2c80ff;

        svg {
          transform: rotate(90deg);

          path {
            fill: currentcolor;
          }
        }
      }
    }
  }
}
