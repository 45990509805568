.home-featured-projects {
  width: 100%;

  .home-featured-projects__header {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;

    .header-title {
      display: flex;
      align-items: center;

      .header-title_input {
        max-width: 280px;
        font-size: 2.5rem;
        font-weight: 700;
      }
    }

    .header-description {
      width: 390px;
      font-size: 1.25rem;
      font-weight: 500;
    }

    .header-button {
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .home-featured-projects__cases {
    margin-top: 40px;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;

    .add-item {
      width: 100%;
      min-height: 300px;

      .add-item__inner {
        width: 100%;
        height: 100%;
        display: flex;
        border-color: #e4e6ef;
        border-width: 2px !important;
        border-style: dashed !important;
        border-radius: 20px;
      }
    }

    .featured-case {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      img {
        border-radius: 20px;
      }

      .case-title {
        display: block;
        margin-top: 20px;
        font-size: 1.5rem;
        font-weight: 700;
      }

      .case-description {
        display: block;
      }

      .case-categories {
        display: flex;
        .case-categories__item {
          display: block;
        }
      }

      .case-tags-list {
        position: relative;
        width: 100%;

        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.75rem;

        .case-tags-list__item {
          border-radius: 2px;

          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .figure-with-overlay {
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: right;
        align-items: center;

        .figure-with-overlay__base-image {
          max-width: 91%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          position: absolute !important;
        }

        .figure-with-overlay__overlay-image {
          z-index: 1;
          max-width: 132px;
          box-shadow: 0px 24px 38px rgba(30, 23, 84, 0.05),
            0px 9px 46px rgba(30, 24, 86, 0.1),
            0px 11px 15px rgba(30, 24, 85, 0.1);
        }
      }
    }
  }
}
