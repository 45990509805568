.case-header {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-header__content {
        grid-column: 2/span 10;

        display: flex;
        align-items: center;
        gap: 3rem;

        .content-header {
            width: 100%;
            display: flex;
            flex-direction: column;

            .header-title {
                width: 100%;
                font-size: 1.25rem;
                font-weight: 600;
                color: #2c80ff;
            }

            .header-heading {
                width: 100%;
                margin-top: 0.25rem;
                font-size: 2.5rem;
                line-height: 1;
                font-weight: 700;

            }

            .header-post-type {
                position: relative;
                width: 100%;

                margin-top: 0.5rem;
                margin-bottom: 0.5rem;

                display: flex;
                flex-wrap: wrap;
                gap: 0.75rem;

                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.75rem;
                line-height: 1.75rem;

                .post-type-category {
                    border-radius: 2px;

                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

        }

        .content-description {
            width: 100%;
            margin-top: 1.25rem;
            font-size: 1.25rem;
        }

        .content-action {
            a {
                border-radius: 1.25rem;
                border-width: 2px;
                border-style: solid;
                border-color: #2C80FF;

                background-color: #2C80FF;
                color: #fff;
                fill: #fff;

                padding: 0.5rem 1rem
            }

            .action-text {
                text-align: center;
                font-weight: 600;
                color: #fff;
                fill: #fff;
            }
        }

    }
}