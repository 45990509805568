.blog-header {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 2.25rem;

	align-items: center;

	.blog-header__figure {
		width: 100%;
		grid-column: 1 / span 7;

		overflow: hidden;
		border-radius: 1.25rem;

		img {
			width: 100%;
			max-height: 38rem;
			object-fit: cover;
		}
	}

	.blog-header__content {
		width: 100%;
		grid-column: 8 / span 5;

		.content-post-types {
			position: relative;
			width: 100%;

			margin-top: 1rem;
			margin-bottom: 1rem;

			display: flex;
			flex-wrap: wrap;
			gap: 0.75rem;

			text-transform: uppercase;
			font-weight: 700;
			font-size: 0.875rem;
			line-height: 1.375rem;

			.post-type-category {
				border-radius: 2px;

                padding-left: 10px;
                padding-right: 10px;

                background-color: #2C80FF;
                color: #fff;
                fill: #fff;
			}
		}

		.content-title {
			width: 100%;
            font-size: 2.25rem;
            font-weight: 600;
		}

		.content-reading-time {
			width: 100%;
			font-size: 1rem;
		}

		.content-overview {
			box-shadow: 0px 20px 24px rgb(17 17 17 / 6%);

			border-radius: 8px;
			padding: 1.5rem;

			background-color: #fcfcfc;

			.overview-heading {
				font-size: 1.5rem;
				font-weight: 600;

				margin-bottom: 0.25rem;
			}

			.overview-list {
				.overview-list-item {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;

					display: flex;
					justify-content: space-between;
					align-items: center;

					font-size: 1rem;
					font-weight: 600;
					line-height: 1rem;

					color: #000;

					.overview-list-item__icon {
						transform: rotate(90deg);
						fill: #2c80ff;
					}
				}
			}
		}
	}
}

.blog-header-author {
	width: 100%;

	margin-top: 2.5rem;
	margin-bottom: 2.5rem;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.author-content {
		width: 100%;
		grid-column: 2 / span 10;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.author-content__info {
			display: flex;
			align-items: center;

			.info-figure {
				position: relative;

				margin-right: 1.75rem;

				img {
					border-radius: 50%;
					width: 90px;
					min-width: 90px;
					height: 90px;
				}
			}

			.info-content {
				display: flex;
				flex-direction: column;

				.info-content__name {
					font-size: 1.25rem;
					font-weight: 700;
				}

				.info-content__department {
					font-size: 1.25rem;
					font-weight: 500;
				}

				.info-content__date {
					font-size: 1rem;
					font-weight: 500;
				}
			}
		}

		.author-content__action {
			display: flex;
			gap: 1.5rem;
			align-items: center;

			border-radius: 2rem;

			padding: 1.25rem 2.25rem;

			background-color: #eff5ff;
			border-color: #eff5ff;

			.action-text {
				color: #2c80ff;
				fill: #2c80ff;

				font-weight: 600;
				text-align: center;
			}

			.action-icon {
				color: #2c80ff;
				fill: #2c80ff;
			}
		}
	}
}
