.blog-content-wide-description-two-column {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.blog-content {
		width: 100%;

		grid-column: 2 / span 10;

		.blog-content__title {
			width: 100%;
			font-size: 2rem;
			font-weight: 700;
			color: $primary1;
		}

		.ck-editor__main {
			&:hover {
				cursor: auto !important;
			}

			.ck-editor__editable {
				gap: 70px;
				column-count: 2;
				word-wrap: break-word;

				p {
					margin-top: 0px;
				}
			}
		}
	}
}
