.plugin-left-screenshots-info {
  width: 100%;
  display: flex;
  position: relative;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eff5ff;
    content: '';

    border-radius: 20px;
  }

  .showcase {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;

    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

    .plugin-left-screenshots-info__content {
      grid-column: 8 / span 5;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .content-title {
        width: 100%;
        font-size: 2rem;
        font-weight: 600;
        z-index: 1;
      }

      .content-description {
        width: 100%;
      }

      .content-action {
        width: 100%;
        margin-top: 0.5rem;

        a {
          border-radius: 1.25rem;
          border-width: 2px;
          border-style: solid;
          border-color: #2c80ff;

          background-color: #2c80ff;
          color: #fff;
          fill: #fff;

          padding: 0.5rem 1rem;
        }

        .action-text {
          text-align: center;
          font-weight: 600;
          color: #fff;
          fill: #fff;
        }
      }
    }

    .plugin-left-screenshots-info__figure {
      grid-column: 1 / span 7;
      position: relative;
    }
  }
}
