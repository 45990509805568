.case-header-website-screenshot {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-header-website-screenshot__content {
        grid-column: 2/span 10;

        display: flex;
        align-items: center;
        gap: 1.25rem;
    }
}