.blog-author-header {
	width: 100%;

  font-family: var(--fontRegular);

	margin-top: 2.5rem;
	margin-bottom: 2.5rem;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.author-content {
		width: 100%;
		grid-column: 2 / span 10;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.author-content__info {
			display: flex;
			align-items: center;

			.info-figure {
				position: relative;

				margin-right: 1.75rem;

				img {
					border-radius: 50%;
					width: 90px;
					min-width: 90px;
					height: 90px;
				}
			}

			.info-content {
				display: flex;
				flex-direction: column;

				.info-content__name {
					font-size: 1.325rem;
					font-weight: 900;
				}

				.info-content__department {
					font-size: 1.25rem;
					font-weight: 400;
				}

				.info-content__date {
          margin-top: 0.25rem;
					font-size: 1rem;
					font-weight: 500;
				}
			}
		}

		.author-content__action {
			display: flex;
			gap: 1.5rem;
			align-items: center;

			border-radius: 2rem;

			padding: 1.25rem 2.25rem;

			background-color: #eff5ff;
			border-color: #eff5ff;

			.action-text {
				color: #2c80ff;
				fill: #2c80ff;

				font-weight: 600;
				text-align: center;
			}

			.action-icon {
				color: #2c80ff;
				fill: #2c80ff;
			}
		}
	}
}
