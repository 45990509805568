.home-header {
    position: relative;
    height: 625px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;

    .home-header__content {
        z-index: 1;
        max-width: 525px;
        border-radius: 20px;
        background-color: #fff;
        color: #2a292c;
        fill: #2a292c;

        @media (min-width: $screen-small) {
          padding: 40px 43px 46px 42px;
        }
        padding: 40px 30px 30px;
        box-shadow: 0px 24px 38px rgba(30, 23, 84, 0.05),
          0px 9px 46px rgba(30, 24, 86, 0.1), 0px 11px 15px rgba(30, 24, 85, 0.1);

        .content-title {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c80ff;
        }

        .content-heading {
            max-width: 390px;
            width: auto !important;
            font-size: 3.5rem;
            line-height: 1.25;
            font-weight: 700;
        }

        .content-description {
            margin-top: 12px;
            width: 100%;
            max-width: 390px;
            font-size: 1.25rem;
            font-weight: 500;
        }

        .content-action {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .content-action__button {
                display: block;
            }

            .content-action__icons {
                display: grid;
                gap: 1.25rem;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }

    .home-header__figure {
        width: 91%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute !important;

        img {
            border-radius: 20px;
        }
    }
}
