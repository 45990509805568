.blog-author-footer {
	width: 100%;

	font-family: var(--fontRegular);

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.blog-content {
		width: 100%;

		grid-column: 2 / span 10;

		display: flex;
		align-items: center;
		gap: 4rem;

		border-radius: 1.25rem;
		padding: 2rem 4rem;
		background-color: $primary2;

		.blog-content__text {
			position: relative;

			.text-editor__container {
				position: absolute;
				z-index: 4;

				bottom: 0;
				left: 0;
				transform: translateY(100%);

				margin-bottom: -1rem;

				display: flex;
				flex-direction: column;

				.container-editor {
					padding: 2rem;
					border-radius: 1.25rem;
				}
			}

			.text-value {
				color: #fff;
				font-size: 1.325rem;
			}
		}

		.blog-content__action {
			a {
				border-radius: 1.25rem;

				background-color: #fff;
				border-color: #fff;

				padding-right: 1.25rem;
				padding-left: 1.25rem;
				padding-top: 8px;
				padding-bottom: 8px;

				input {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}

		.blog-content__avatar {
			position: relative;

			img {
				border-radius: 50%;
				width: 90px;
				min-width: 90px;
				height: 90px;
			}
		}

		.ck-editor__main {
			&:hover {
				cursor: auto !important;
			}
		}
	}
}
