.reviews-element {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .heading {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  .rating {
    gap: 10px;
    align-items: center;
    display: flex;
    margin-bottom: 30px;

    .rating__stars {
      gap: 10px;
      display: flex;
      fill: #ffb800;

      .rating__filled {
        height: 22px;
        min-width: 22px;
        width: 22px;
      }

      .rating__empty {
        opacity: 0.4;
        height: 22px;
        min-width: 22px;
        width: 22px;
      }
    }

    .rating__score {
      font-weight: 700;
      line-height: 2.2rem !important;
      letter-spacing: 0.7px;
    }
  }

  .review__cards {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #d9dbe9;
    border-style: solid;
    border-width: 1px 0;
    gap: 30px;
    padding-bottom: 48px;
    padding-top: 54px;
    margin-bottom: 35px;
  }

  .review__buttons {
    gap: 15px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;

    .button {
      border-style: solid;
      border-width: 2px;
      box-shadow: 0 0 0 0 transparent;
      flex-wrap: nowrap;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
      width: 100% !important;
    }

    .button__primary {
      fill: #fff;
      background-color: #2c80ff;
      border-color: #2c80ff;
      color: #fff !important;
      border-radius: 20px;
      font-size: 14px;
      gap: 10px;
      align-items: center;
      display: flex;
      width: 100% !important;
    }

    .button__secondary {
      fill: #2a292c;
      border-color: #2a292c;
      color: #2a292c;
      background-color: transparent;
      border-radius: 20px;
      font-size: 14px;
      gap: 10px;
      align-items: center;
      display: flex;
      width: 100% !important;
    }

    .button__text {
      padding-right: 30px;
      padding-bottom: 8px;
      padding-left: 30px;
      padding-top: 8px;
      text-align: center;
      width: 100%;
      color: inherit !important;
    }
  }
}
