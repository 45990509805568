.about-us-content-description {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12,1fr);

    .description__content {
        width: 100%;
        grid-column: 2/span 10;

        padding: 3rem 2.5rem 2.25rem;

        display: flex;
        flex-direction: column;

        border-radius: 1.5rem;
        border-style: solid;
        border-width: 0.25rem;
        border-color: #d9e8ff;

        .content-title {
            width: 100%;
            font-size: 1.75rem;
            font-weight: 700;
        }

        .content-description {
            width: 100%;
            margin-top: 1rem;
            font-size: 1.125rem;
        }

        .content-action {
            margin-top: 1.5rem;
        }
    }

}