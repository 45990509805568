.specializations-full-text {
  width: 100%;

  display: flex;
  flex-direction: column;

  .specializations-full-text__heading {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .text-heading {
      grid-column: 1 / span 10;
      font-size: 2.75rem;
      font-weight: 700;
    }
  }

  .specializations-full-text__message {
    margin-top: 1.5rem;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .text-message {
      grid-column: 1 / span 12;
      font-size: 1.25rem;
    }
  }
}
