.contacts-other-info-with-icons {
  margin-top: 35px;
  margin-bottom: 35px;

  position: relative;

  .grid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    gap: auto 30px;

    .content {
      grid-column: 1 / span 6;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 15px;

      .heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.1rem;
        line-height: 2.1rem;
        margin-bottom: 15px;
        color: $primary1;
        width: 100%;
      }

      .description {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $primary1;
        width: 100%;
        word-wrap: break-word;
        margin-bottom: 20px;
        font-family: $regular;
      }
    }

    .usp {
      width: 100%;
      grid-column: 7 / span 12;

      gap: 30px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;

      background-color: var(--primaryColor);
      fill: var(--secondaryColor);

      padding: 25px;

      .usp__inner {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: 100%;

        justify-content: center;
      }

      .usp__item {
        gap: 15px;
        align-items: center;
        display: flex;
        fill: var(--secondaryColor);

        align-items: center;

        .icon__outer {
          .icon {
            min-width: 32px;
            width: 24px !important;
            height: 24px !important;
          }
        }

        .usp__item-description {
          width: 100%;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 30px;
          font-size: 0.9rem;
          line-height: 1.2rem;
          letter-spacing: 0.25px;
          word-wrap: break-word;
          color: #fff;
          font-family: $regular;
        }
      }
    }
  }
}
