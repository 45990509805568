.plugin-array-header-and-description {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;

  .card {
    position: relative;

    .heading {
      font-weight: 700;
      font-size: 1.8rem;
      letter-spacing: 1px;
      line-height: 2rem;
      margin-bottom: 15px;
      width: 100%;
    }

    .description {
      letter-spacing: 0.7px;
      font-size: 1.2rem;
      line-height: 2rem;
      word-wrap: break-word;
      margin-bottom: 15px;
      width: 100%;
    }

    .button {
      flex-wrap: wrap;
      display: flex;

      .button__text {
        fill: #2c80ff;
        color: #2c80ff;
        font-size: 14px;
        gap: 10px;
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
        width: fit-content;
      }
    }
  }
}
