.specializations-header {
  width: 100%;
  display: grid;
  gap: 4.5rem;
  grid-template-columns: repeat(12, 1fr);

  .specializations-header__content {
    width: 100%;
    grid-column: 1 / span 6;
    order: 1;

    display: flex;
    flex-direction: column;

    .content-title {
      width: 100%;
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c80ff;
    }

    .content-heading {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 2.5rem;
      line-height: 1;
      font-weight: 700;
    }

    .content-description {
      width: 100%;
      margin-top: 1.25rem;
      font-size: 1.25rem;
    }

    .content-action-container {
      display: flex;
      margin-top: 1.25rem;

      .content-action {
        margin-right: 15px;
      }
    }
  }

  .specializations-header__figure {
    width: 100%;
    grid-column: 7 / span 7;
    order: 2;

    overflow: hidden;
    border-radius: 1.5rem;

    img {
      max-width: 100%;
      min-height: 23rem;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
