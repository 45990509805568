@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --fontFancy: 'Poppins', sans-serif;
  --fontRegular: 'Poppins', sans-serif;

  --primaryColor: #3336ad;
  --primaryColorDarker: #20238b;
  --primaryColorDark: #484bd2;
  --primaryColorLight: #d2d3ff;
  --primaryColorLighter: #f1edff;

  --secondaryColor: #febcbd;
  --secondaryColorDarker: #f4a5a6;
  --secondaryColorDark: #ffcdce;
  --secondaryColorLight: #fcd9da;
  --secondaryColorLighter: #fff1f2;

  --succesColor: #68c89a;
}

// project colors
$black: #000;
$primary1: var(--primaryColor); // default
$primary2: var(--primaryColorDarker); // darker
$primary3: var(--primaryColorDark); // dark
$primary4: var(--primaryColorLight); // light
$primary5: var(--primaryColorLighter); // BG

$secondary1: var(--secondaryColor); // default
$secondary2: var(--secondaryColorDarker); // darker
$secondary3: var(--secondaryColorDark); // dark
$secondary4: var(--secondaryColorLight); // light
$secondary5: var(--secondaryColorLighter); // BG

@import './settings';
@import './color';
@import './text';
@import './display';
@import './home/home';
@import './blog/blog';
@import './about-us/about-us';
@import './contacts/contacts';
@import './specializations/specializations';
@import './case/case';
@import './reviews/reviews';
@import './plugin/plugin';

.content-list {
  font-family: 'Azeret Mono', sans-serif;
}

.element-h1 {
  font-size: 2.75rem !important;
  font-weight: 700 !important;
}

.element-h2 {
  font-size: 2rem !important;
  font-weight: 600 !important;
}

.element-h3 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

.ck-editor__main {
  a {
    color: #2a292c;
    text-decoration: underline;
  }
}

.body-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.header-drop-container-placeholder {
  min-height: 30rem !important;
  width: 100%;
  border: 1;
  border-style: dashed;
}

.inline-edit-input {
  background-color: transparent;
  border: 0;

  &:hover {
    border: 1;
  }
}

.multi-line-edit-textarea {
  background-color: transparent;
  border: 0;
  resize: none !important;
  overflow: hidden;
  min-height: 15px;
  &:focus {
    outline: 5px auto Highlight; /* Firefox */
    outline: 5px auto -webkit-focus-ring-color; /* Chrome, Safari */
  }
}

.body-drag {
  &:hover {
    cursor: pointer;
    border: 1;
    border-width: 0.125rem;
    border-style: dashed;
  }
}

.select-other-case {
  li {
    &:hover {
      background-color: $green1;
    }
  }
}

ul {
  list-style: none;
}

.other-case-list {
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}

.link-tooltip-wrapper {
  section {
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

.section-outline-none {
  section {
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

// Project Highlights
.can-drop-highlight {
  background-color: $primary5;
}

.is-over-highlight {
  background-color: $yellow5;
}

.post-types-dropdown-header {
  &:hover {
    cursor: pointer;
  }
}

.post-types-dropdown-header-open {
  padding: 0.25rem;
  border: 1;
  border-width: 2px;
  border-style: solid;
}
