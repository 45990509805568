.plugin-partners {
  width: 100%;
  display: flex;
  align-items: center;

  .plugin-partners__content {
    max-width: 240px;
    width: 100%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;

    .content-title {
      max-width: 240px;
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c80ff;
    }

    .content-heading {
      max-width: 240px;
      width: auto !important;
      margin-top: 8px;
      font-size: 2.5rem;
      line-height: 1;
      font-weight: 700;
    }

    .content-description {
      max-width: 240px;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  .plugin-partners__carousel {
    width: 100%;

    .rec-arrow {
      min-width: 2rem !important;
      width: 2rem;
      height: 2rem;
      font-size: 1.25rem;
      line-height: 0.25rem;
    }

    .add-item {
      width: 180px;
      height: 200px;
      display: flex;
      border-color: #e4e6ef;
      border-width: 2px !important;
      border-style: dashed !important;
      border-radius: 20px;
    }

    .employee {
      position: relative;

      .item {
        position: relative;
        width: 180px;
        height: 400px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .item-figure {
          width: 100%;
          height: 400px;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 20px;
        }

        .item-content {
          z-index: 1;

          .item-content__name {
            display: block;
            color: #fff;
          }

          .item-content__department {
            display: block;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }
}
