.blog-featured {
	width: 100%;

	.blog-featured__heading {
		display: flex;
        align-items: center;
        justify-content: space-between;

        .heading-title {
			width: 100%;
            font-size: 1.75rem;
            font-weight: 700;
        }

        .heading-action {
            a {
                border-radius: 1.25rem;
                border-width: 2px;
                border-style: solid;
                border-color: #2A292C;

                background-color: transparent;
                color: #2A292C;
                fill: #2A292C;

                padding: 0.5rem 1rem
            }

            .action-text {
                text-align: center;
                font-weight: 600;
            }
        }
	}

	.blog-featured__list {
		width: 100%;

		margin-top: 3.25rem;

		display: grid;
		gap: 2rem;
		// grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
		grid-template-columns: 1fr 1fr 1fr;

        .add-item {
            width: 100%;
            min-height: 300px;

            .add-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                border-color: #e4e6ef;
                border-width: 2px !important;
                border-style: dashed !important;
                border-radius: 20px;
            }
        }

		.list-item {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;

			.item-figure {
				overflow: hidden;
				border-radius: 0.75rem;

				img {
					width: 100%;
					max-height: 18rem;
					object-fit: cover;
				}
			}

			.item-post-type {
				position: relative;
				width: 100%;

				margin-top: 1rem;

				display: flex;
				flex-wrap: wrap;
				gap: 0.75rem;

				text-transform: uppercase;
				font-weight: 700;
				font-size: 0.875rem;
				line-height: 1.375rem;

				.post-type-category {
					border-radius: 2px;

                    padding-left: 10px;
                    padding-right: 10px;

                    background-color: #2C80FF;
                    color: #fff;
                    fill: #fff;
				}
			}

			.item-title {
				font-size: 1.25rem;
				font-weight: 700;
			}
		}
	}
}
