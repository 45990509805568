.about-us-unique-selling-point {
  width: 100%;
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));

  .add-item {
    width: 100%;
    min-height: 365px;
    display: flex;
    border-color: #e4e6ef;
    border-width: 2px !important;
    border-style: dashed !important;
    border-radius: 20px;
  }

  .item {
    position: relative;
    width: 100%;
    display: grid;
    gap: 2rem;

    &:nth-child(even) {
      .item__figure {
        order: 2;
      }
    }

    .item__content {
      width: 100%;
      display: flex;
      flex-direction: column;

      .content-title {
        width: 100%;
        font-size: 2.25rem;
        font-weight: 700;
      }

      .content-description {
        margin-top: 1.25rem;
        width: 100%;
        font-size: 1.25rem;
      }
    }

    .item__figure {
      .svg-icon {
        overflow: hidden;
      }
      .icon {
        display: flex;
        height: 8rem;
        width: 8rem;
        overflow: hidden;
        border-radius: 1.5rem;
        align-items: center;
        justify-content: center;

        background-color: #2c80ff;
        color: #fff;

        svg {
          height: 6rem;
          min-width: 6rem;

          path {
            fill: currentcolor;
          }
        }

        img {
          height: 6rem;
          min-width: 6rem;

          fill: currentcolor;
        }
      }
    }
  }
}
