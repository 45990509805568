.specializations-modal-steps {
  display: grid;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  fill: #fff;
  background-color: #2a292c;
  color: #fff;

  max-width: 1160px;

  border-radius: 10px;
  padding: 15px;

  .inner {
    padding: 25px 15px 25px 15px;

    .specializations-modal-steps__heading {
      position: relative;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 1rem;
      letter-spacing: 1px;

      margin-bottom: 10px;
      color: inherit;

      width: 100%;
    }

    .specializations-modal-steps__description {
      letter-spacing: 1px;
      color: inherit;
      font-size: 1.2rem;
      line-height: 1.5rem;
      word-wrap: break-word;
      margin-bottom: 35px;
      width: 100%;
    }

    .specializations-modal-steps__list {
      counter-reset: count;
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 25px 15px;
      margin-bottom: 25px;

      .specializations-modal-steps__item {
        fill: #2a292c;
        background-color: #fff;
        color: #2a292c;

        border-radius: 15px;

        position: relative;

        transition-delay: calc(0.05s * 0);
        transition-duration: 0.2s;
        transition-property: opacity;
        transition-timing-function: ease-in-out;

        padding: 28px;

        .add-item {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;

          border-width: 4px;
          border-radius: 15px;
          border-color: #2a292c;
          border-style: solid;

          cursor: pointer;

          margin: 0px 30px 50px 25px;
        }

        .step_number {
          display: flex;
          line-height: 1.8rem !important;
          word-wrap: break-word;
        }

        .heading {
          font-weight: 700;
          font-size: 2.2rem;
          line-height: 1rem;
          letter-spacing: 1px;
          margin-bottom: 15px;

          height: 30px;
        }

        .description {
          word-wrap: break-word;
          width: 100%;
          margin-bottom: 10px;
        }

        .button {
          fill: #2a292c;
          border-color: #d9e8ff;
          color: #2a292c;
          background-color: transparent;
          border-radius: 20px;
          font-size: 14px;
          gap: 15px;
          align-items: center;
          display: flex;

          border-style: solid;
          border-width: 2px;
          box-shadow: 0 0 0 0 transparent;
          cursor: pointer;
          flex-wrap: nowrap;
          font-weight: 600;
          line-height: 30px;
          white-space: nowrap;
          overflow: hidden;

          padding: 5px 15px 5px 15px;

          :hover {
            fill: #2a292c;
            background-color: #fff;
            color: #2a292c;
          }
        }

        .card-tags {
          line-height: 2.2rem !important;
          font-size: 10px;
          letter-spacing: 0.7px;
          margin-bottom: 20px;

          position: absolute;
          top: 0;
          transform: translateY(-50%);

          gap: 8px;

          text-transform: uppercase;

          font-weight: 700;

          .tag {
            border-radius: 2px;
            padding-left: 10px;
            padding-right: 10px;

            fill: #fff;
            background-color: #9c41e3;
            color: #fff;
          }
        }

        .button-delete {
          position: absolute;
          top: 0;
          right: 15%;
          transform: translateY(-50%);

          cursor: pointer;
        }
      }
    }
  }
}
