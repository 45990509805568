.specializations-right-modal {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-gap: 30px;

  .specializations-right-modal__content {
    border-radius: 20px;
    box-shadow: 0 24px 38px rgb(30 23 84 / 5%), 0 9px 46px rgb(30 24 86 / 10%),
      0 11px 15px rgb(30 24 85 / 10%);
    padding: 20px 15px 20px 20px;

    z-index: 1;
    position: relative;
    grid-row: 1;

    fill: #2a292c;
    background-color: #fff;
    color: #2a292c;

    display: flex;
    flex-direction: column;

    // grid-column: 8 / span 5;
    // order: 2;

    @media (min-width: 768px) and (min-width: 960px) and (max-width: 1199px) {
      grid-column: 7 / span 5;
    }

    @media (min-width: 768px) {
      grid-column: 6 / span 6;
      order: 2;
    }

    @media (min-width: 768px) and (min-width: 1200px) {
      grid-column: 8 / span 5;
    }

    .content-heading {
      font-weight: 700;
      margin-bottom: 1.5rem;
      font-size: 2.25rem;
      margin-top: 0.25rem;
      width: 100%;
      line-height: 1;
    }

    .content-description {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
  }

  .specializations-right-modal__figure {
    grid-column: 1 / span 8;
    grid-row: 1;
    order: 1;

    .content-image {
      overflow: hidden;
      border-radius: 1.5rem;

      img {
        max-width: 100%;
        min-height: 23rem;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
