.case-featured-other-cases {
    width: 100%;

    .case-featured-other-cases__content {
        width: 100%;

        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 700;
        }
    }

    .case-featured-other-cases__list {
        width: 100%;
        margin-top: 2rem;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;

        .add-item {
            width: 100%;
            min-height: 300px;

            .add-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                border-color: #e4e6ef;
                border-width: 2px !important;
                border-style: dashed !important;
                border-radius: 20px;
            }
        }

        .list-item {
            height: 420px;
            overflow: hidden;
            border-radius: 1.25rem;

            position: relative;

            .list-item__figure {
                position: absolute;
                z-index: 1;

                width: 100%;
                height: 420px;
                object-fit: cover;
            }

            .list-item__container {
                height: 100%;
                position: relative;
                z-index: 2;

                padding: 1.25rem;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;


                .container-content {
                    position: relative;
                    border-radius: 10px;

                    background-color: #fff;
                    color: #2A292C;
                    fill: #2A292C;

                    padding: 1.25rem;
                    padding-top: 1.75rem;

                    .content-labels {
                        position: absolute;

                        left: 2rem;
                        top: 0;
                        left: 1.25rem;
                        transform: translateY(-50%);

                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.5rem;

                        .labels-item {
                            border-radius: 2px;

                            padding-left: 0.5rem;
                            padding-right: 0.5rem;
                            padding-bottom: 0.125rem;

                            .labels-item__title {
                                font-size: 0.75rem;
                                font-weight: 700;
                                text-transform: uppercase;

                                line-height: 1.75rem!important;

                                color: white;
                            }
                        }
                    }

                    .content-title {
                        font-weight: 600;
                    }
                }
            }

        }

    }

}