.specializations-cases {
  display: grid;
  grid-template-columns: auto;
  width: 100%;

  .specializations-cases__content {
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    justify-content: flex-end;
    justify-items: flex-end;
    align-items: center;

    .content-heading {
      font-weight: 700;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      margin-top: 0.25rem;
      width: 100%;
      line-height: 1;
    }

    .content-description {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      word-wrap: break-word;
      min-width: 350px;
      width: 100%;
    }
  }

  .specializations-cases__list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    column-gap: 10px;

    .add-item {
      width: 100%;
      min-height: 300px;

      .add-item__inner {
        width: 100%;
        height: 100%;
        display: flex;
        border-color: #e4e6ef;
        border-width: 2px !important;
        border-style: dashed !important;
        border-radius: 20px;
      }
    }

    .card {
      border-radius: 10px;
      position: relative;

      .specializations-cases__figure {
        overflow: hidden;
        border-radius: 10px;

        img {
          max-width: 100%;
          min-height: 23rem;
          max-height: 28rem;
          object-fit: cover;
        }
      }

      .card__content {
        bottom: 23px;
        left: 23px;
        padding: 26px 23px 23px;
        right: 23px;
        z-index: 1;
        border-radius: 10px;
        position: absolute !important;

        fill: #2a292c;
        background-color: #fff;
        color: #2a292c;

        .card__tags {
          left: 13px;
          top: 0;
          transform: translateY(-50%);
          gap: 8px;
          display: flex;
          position: absolute !important;
          text-transform: uppercase;
          font-weight: 700;
          line-height: 1.2rem !important;
          font-size: 8px;
          letter-spacing: 0.7px;

          .card__tag {
            border-radius: 2px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .tag-green {
    fill: #fff;
    background-color: #31c1a7;
    color: #fff;
  }

  .tag-pink {
    fill: #fff;
    background-color: #ef5da8;
    color: #fff;
  }
}
