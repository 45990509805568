.case-person-highlight-experience {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-person-highlight-experience__container {
        grid-column: 1/span 11;

        padding: 3.25rem 0 4.25rem 3.75rem;

        display: flex;
        gap: 40px;

        border-radius: 20px;

        background-color: #2A292C;

        .container__person {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            color: white;

            .person-figure {
                border-radius: 50%;
                width: 90px;
                min-width: 90px;
                height: 90px;

                object-fit: cover;
            }

            .person-name {
                display: block;
                font-size: 1.25rem;
                font-weight: 700;
            }

            .person-business-name {
                display: block;
            }

        }

        .container__content {
            display: flex;
            flex-direction: column;

            .content-title {
                width: 100%;
                font-size: 1.25rem;
                font-weight: 600;
                color: #2c80ff;

            }

            .content-heading {
                width: 100%;
                margin-top: 0.25rem;
                font-size: 2.75rem;
                line-height: 1;
                font-weight: 500;
                color: white;
            }

            .content-description {
                width: 100%;
                margin-top: 1.25rem;
                color: white;
            }

            .content-action {
                margin-top: 4rem;

                a {
                    border-radius: 1.25rem;
                    border-width: 2px;
                    border-style: solid;
                    border-color: #2C80FF;

                    background-color: #2C80FF;
                    color: #fff;
                    fill: #fff;

                    padding: 0.5rem 1rem
                }

                .action-text {
                    text-align: center;
                    font-weight: 600;
                    color: #fff;
                    fill: #fff;
                }
            }

        }

        .container__figure {
            // min-width: 25rem;
            margin-right: -7.5rem;
            align-self: center;
            position: relative;
            top: unset;
            left: unset;
            right: unset;

            img {
                width: 100%;
                max-height: 24rem;
                object-fit: cover;
            }
        }
    }

}