.blog-content-description-with-info {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.blog-content {
		width: 100%;

		grid-column: 3 / span 8;

		.blog-content__title {
			width: 100%;
			font-size: 2rem;
			font-weight: 700;
			color: $primary1;
		}

		.ck-editor__main {
			&:hover {
				cursor: auto !important;
			}
		}

		.blog-content__info {
			margin-top: 1.75rem;

			background-color: #eff5ff;
			border: 1px solid #d9e8ff;
			display: block;
			border-radius: 20px;
			padding: 1.75rem 2.25rem;
		}
	}
}
