.home-plugins {
    width: 100%;

    .home-plugins__header {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr;

        .header-title {
            display: flex;
            align-items: center;

            .header-title_input {
                max-width: 280px;
                font-size: 2.5rem;
                font-weight: 700;
            }
        }

        .header-description {
            width: 390px;
            font-size: 1.25rem;
            font-weight: 500;
        }

        .header-button {
            max-width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .home-plugins__carousel {
        width: 100%;

        .rec-arrow {
            min-width: 2rem !important;
            width: 2rem;
            height: 2rem;
            font-size: 1.25rem;
            line-height: 0.25rem;
        }

        .add-item {
            padding-top: 5rem;
            width: 200px;
            min-height: 352.5px;

            .add-item__inner {
                padding-top: 5rem;
                width: 100%;
                height: 100%;
                display: flex;
                border-color: #e4e6ef;
                border-width: 2px !important;
                border-style: dashed !important;
                border-radius: 20px;
            }
        }

        .item {
            position: relative;
            padding-top: 5rem;
            width: 200px;
            height: 100%;

            .item__inner {
                position: relative;
                padding: 16px;
                padding-top: 5rem;
                width: 100%;
                height: 100%;
                border-radius: 20px;

                .icon {
                    position: absolute;
                    z-index: 2;

                    top: 0;
                    transform: translate(-50%, -50%);
                    left: 50%;

                    width: 96px;
                    height: 96px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    filter: drop-shadow(0px 6px 13px rgba(17, 17, 17, 0.06));
                    border-radius: 50%;
                    background-color: #fff;

                    .icon__placeholder {
                        width: 60px !important;
                        height: 60px !important;
                    }
                }

                .title-container .description-container {
                    min-height: 88px !important;
                }

                .title {
                    width: 100%;
                    font-weight: 700;
                }

                .description {
                    width: 100%;
                }

                .action {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .action__link {
                        display: inline-flex;

                        .text {
                            font-weight: 600;
                        }

                        .text-icon {
                            transform: rotate(90deg);
                        }
                    }

                    .action__button {
                        width: 40px;
                        height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: #2c80ff;
                        fill: #2c80ff;

                        font-size: 12px;
                        font-weight: 600;

                        background-color: #d9e8ff;
                        border-color: #d9e8ff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}