.about-us-showcase-with-text-left {
  width: 100%;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(12, 1fr);

  .showcase__content {
    width: 100%;

    grid-column: 1 / span 5;

    display: flex;
    align-items: center;

    .content-inner {
      .content-title {
        width: 100%;
        font-size: 2rem;
        font-weight: 600;
      }

      .content-description {
        width: 100%;
        margin-top: 1.25rem;
        font-size: 1.125rem;
      }
    }
  }

  .showcase__figure {
    width: 100%;

    grid-column: 6 / span 7;

    overflow: hidden;
    border-radius: 1.5rem;

    img {
      min-width: 100%;
      max-height: 24rem;
      object-fit: cover;
    }
  }
}
