.blog-content-heading {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	.blog-content {
		width: 100%;

		grid-column: 2 / span 10;

		.blog-content__title {
			width: 100%;
            font-size: 2.75rem;
            font-weight: 700;
		}

		.blog-content__description {
			width: 100%;
            font-size: 2rem;
            font-weight: 600;
		}

		.blog-content__editor {
			margin-top: 1.25rem;
		}

		.ck-editor__main {
			&:hover {
				cursor: auto !important;
			}
		}
	}
}
