.about-us-introduction {
    width: 100%;

    display: flex;
    flex-direction: column;

    .about-us-introduction__heading {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12,1fr);

        .text-heading {
            grid-column: 1/span 10;
            font-size: 2.75rem;
            font-weight: 700;
        }

    }

    .about-us-introduction__message {
        margin-top: 1.5rem;
        width: 100%;

        display: grid;
        grid-template-columns: repeat(12,1fr);

        .text-message {
            grid-column: 2/span 10;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}