.about-us-image-banner {
    width: 100%;
    overflow: hidden;
    border-radius: 1.5rem;

    img {
        width: 100%;
        height: 34rem;
        object-fit: cover;
    }
}