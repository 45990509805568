.plugin-featured-tutorials {
  display: flex;
  flex-direction: column;
  width: 100%;

  .plugin-featured-tutorials__content {
    display: flex;
    width: 100%;
    flex-direction: column;

    .heading {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.2rem;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .plugin-featured-tutorials__items {
    width: 100%;

    .add-item {
      width: 180px;
      min-height: 200px;

      .add-item__inner {
        width: 100%;
        height: 100%;
        display: flex;
        border-color: #e4e6ef;
        border-width: 2px !important;
        border-style: dashed !important;
        border-radius: 20px;
      }
    }

    .card {
      width: 180px;
      min-height: 200px;
      background-color: #d9e8ff;
      padding: 38px 20px 20px;
      margin-right: 20px;
      position: relative;

      .card__figure {
        box-shadow: 0 2px 1px 0 rgb(0 0 0 / 6%);
        height: 50px;
        width: 100%;

        justify-content: center;
        align-items: center;
        display: flex;
      }

      .card__content {
        position: relative;
        border-radius: 0 0 16px 16px;
        padding-top: 10px;

        .heading {
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        .description {
          line-height: 1.2rem !important;
          letter-spacing: 0.4px;
          font-size: 0.8rem;
          word-wrap: break-word;
          margin-bottom: 10px;
        }
      }

      .link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .text {
          line-height: 1.2rem !important;
          letter-spacing: 0.4px;
          font-size: 0.8rem;
          word-wrap: break-word;
        }
      }
    }
  }
}
