.about-us-featured-content-one {
    width: 100%;

    background-color: $primary1;

    padding: 5rem 6rem 6rem;

    display: flex;
    flex-direction: column;

    border-radius: 1.5rem;

    .content-title {
        width: 100%;
        color: $white;
        fill: $white;
        font-size: 2.5rem;
        font-weight: 700;
    }

    .content-description {
        width: 100%;
        margin-top: 1.25rem;
        font-size: 1.5rem;
        color: $white;
        fill: $white;
    }

    .content-action {
        margin-top: 1.5rem;

        .white-variant-button {
            background-color: #fff !important;

            input {
                color: $primary1 !important;
            }
        }

    }
}