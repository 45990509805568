.case-process-highlight {
    width: 100%;

    padding: 7.25rem 6.25rem;

    border-radius: 20px;
    background-color: #2C80FF;

    .case-process-highlight__content {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;


        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 600;
            color: white;

        }

        .content-description {
            width: 100%;
            color: white;
        }
    }

    .case-process-highlight__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;

        padding: 0;
        margin: 0;
        margin-top: 2.5rem;

        .list-item {
            position: relative;

            border-radius: 1.25rem;
            padding: 2.25rem;

            .item-labels {
                position: absolute;

                left: 2rem;
                top: 0;
                transform: translateY(-50%);

                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;

                .labels-item {
                    border-radius: 2px;

                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    padding-bottom: 0.125rem;

                    .labels-item__title {
                        font-size: 0.75rem;
                        font-weight: 700;
                        text-transform: uppercase;

                        line-height: 2.2rem!important;

                        color: white;
                    }
                }
            }

            .item-step {
                display: block;
                font-size: 1rem;
            }

            .item-title {
                width: 100%;
                font-size: 1.75rem;
                font-weight: 700;
            }

            .item-description {
                width: 100%;
            }

            .item-action {
                width: 100%;
                margin-top: 2rem;

                a {
                    border-radius: 1.25rem;
                    border-width: 2px;
                    border-style: solid;

                    border-color: #D9E8FF;
                    color: #2A292C;
                    fill: #2A292C;

                    padding: 0.5rem 1.5rem
                }

                .action-text {
                    text-align: center;
                    font-weight: 600;
                    color: #2A292C;
                    fill: #2A292C;
                }
            }
        }
    }
}