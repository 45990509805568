// - background
// ----------------------------------------------------------

// project colors
//.color-primary1 {color:$primary1}
.color-primary2 {
  color: $primary2;
}
//.color-primary3 {color:$primary3}
.color-primary4 {
  color: $primary4;
}
.color-primary5 {
  color: $primary5;
}

//.fill-primary1 {fill:$primary1}
.fill-primary2 {
  fill: $primary2;
}
// .fill-primary3 {fill:$primary3}
// .fill-primary4 {fill:$primary4}
// .fill-primary5 {fill:$primary5}

.back-primary1 {
  background-color: $primary1;
  color: $white;
  fill: $white;
}
.back-primary2 {
  background-color: $primary2;
  color: $white;
  fill: $white;
}
.back-primary3 {
  background-color: $primary3;
  color: $white;
  fill: $white;
}
.back-primary4 {
  background-color: $primary4;
}
.back-primary5 {
  background-color: $primary5;
}

// error color
.back-red1 {
  background-color: $red1;
  color: $white;
  fill: $white;
}

// grayscale
//.back-gray1 {background-color:$gray1; color:$black1; fill:$black1;}
//.back-gray2 {background-color:$gray2; color:$black1;fill:$black1;}
.back-gray7 {
  background-color: $gray7;
}
.back-gray8 {
  background-color: $gray8;
}

.back-white {
  background-color: $white;
  color: $primary1;
  fill: $primary1;
}
.back-black {
  background-color: $black;
  color: $white;
  fill: $white;
}

.back-black1 {
  background-color: $black1;
  color: $white;
  fill: $white;
}
//.back-black2 {background-color:$black2; color:$white; fill:$white;}

// department colours
.back-green1 {
  background-color: $green1;
  color: $white;
  fill: $white;
}
.back-purple1 {
  background-color: $purple1;
  color: $white;
  fill: $white;
}
.back-pink1 {
  background-color: $pink1;
  color: $white;
  fill: $white;
}
.back-yellow1 {
  background-color: $yellow1;
  color: $white;
  fill: $white;
}

.fill-green1 {
  fill: $green1;
}
.fill-purple1 {
  fill: $purple1;
}
.fill-pink1 {
  fill: $pink1;
}
.fill-yellow1 {
  fill: $yellow1;
}

.color-green5 {
  color: $green5;
}
.color-purple5 {
  color: $purple5;
}
.color-pink5 {
  color: $pink5;
}
.color-yellow5 {
  color: $yellow5;
}

// - fill
// ----------------------------------------------------------
// .fill-black {fill:$black}
// .fill-black1 {fill:$black1}
.fill-white {
  fill: $white;
}

// - color
// ----------------------------------------------------------
//.color-black {color:$black}
//.color-gray {color:$gray}
.color-white {
  color: $white;
}

// - border
// ----------------------------------------------------------
.line-white {
  border-color: $white;
}

// .line-primary1 {border-color:$primary1}
// .line-primary2 {border-color:$primary2}
// .line-primary3 {border-color:$primary3}
.line-primary4 {
  border-color: $primary4;
}
// .line-primary5 {border-color:$primary5}

// .line-gray1 {border-color:$gray1}
.line-gray2 {
  border-color: $gray2;
}
// .line-gray3 {border-color:$gray3}
// .line-gray4 {border-color:$gray4}
// .line-gray5 {border-color:$gray5}
// .line-gray6 {border-color:$gray6}
// .line-gray7 {border-color:$gray7}
// .line-gray8 {border-color:$gray8}
