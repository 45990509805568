.case-showcase-big-banner {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-showcase-big-banner__content {
        grid-column: 2/span 10;

        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 700;
        }

        .content-description {
            width: 100%;
            margin-top: 1rem;
        }
    }

    .case-showcase-big-banner__figure {
        grid-column: 1/span 12;

        margin-top: 1.25rem;

        overflow: hidden;
        border-radius: 1.25rem;
    }

    .case-showcase-big-banner__caption {
        grid-column: 1/span 12;

        width: 100%;
        margin-top: 0.25rem;

        input {
            width: 100%;
            font-size: 1rem;
        }
    }
}