.case-showcase-with-text-right {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2.5rem;

    align-items: center;


    .case-showcase-with-text-right__figure {
        grid-column: 1/span 7;

        overflow: hidden;
        border-radius: 1.25rem;
    }

    .case-showcase-with-text-right__content {
        grid-column: 8/span 5;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .content-title {
            width: 100%;
            font-size: 2rem;
            font-weight: 600;
        }

        .content-description {
            width: 100%;
        }
    }
}