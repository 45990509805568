.about-us-employees {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12,1fr);

    .about-us-employees__content {
        width: 100%;

        grid-column: 2/span 10;

        display: flex;
        flex-direction: column;

        .content-title {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c80ff;
        }

        .content-heading {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 2.5rem;
            line-height: 1;
            font-weight: 700;
        }

        .content-description {
            width: 100%;
            margin-top: 0.5rem;
        }
    }

    .about-us-employees__department {
        width: 100%;
        margin-top: 1.25rem;

        grid-column: 2/span 10;

        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        .department {
            display: flex;
            align-items: center;

            border-radius: 1.25rem;
            font-size: 0.75rem;
            font-weight: 700;
            gap: 0.5rem;

            fill: #2c80ff;
            background-color: #eff5ff;
            border-color: #eff5ff;
            color: #2c80ff;

            &.department--active {
                fill: #fff;
                background-color: #2c80ff;
                border-color: #2c80ff;
                color: #fff;
            }

            span {
                width: 100%;
                text-align: center;

                padding-right: 30px;
                padding-left: 30px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }

    .about-us-employees__list {
        width: 100%;
        margin-top: 1.25rem;

        padding-left: 3rem;
        padding-right: 3rem;

        grid-column: 1/span 12;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.75rem;

        position: relative;

        .item {
            position: relative;
            width: 180px;
            height: 400px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .item-figure {
                width: 100%;
                height: 400px;
                object-fit: cover;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 20px;
            }

            .item-content {
                z-index: 1;

                .item-content__name {
                    display: block;
                    color: #fff;
                }

                .item-content__department {
                    display: block;
                    color: #fff;
                    font-weight: 700;
                }
            }
        }
    }
}