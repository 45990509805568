.about-us-header {
    width: 100%;
    display: grid;
    gap: 5rem;
    grid-template-columns: repeat(12,1fr);

    .about-us-header__content {
        width: 100%;
        grid-column: 1/span 5;
        order: 1;

        display: flex;
        flex-direction: column;

        .content-title {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c80ff;

        }

        .content-heading {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 2.5rem;
            line-height: 1;
            font-weight: 700;

        }

        .content-description {
            width: 100%;
            margin-top: 1.25rem;
            font-size: 1.25rem;
        }

        .content-action {
            margin-top: 1.25rem;
        }

    }

    .about-us-header__figure {
        width: 100%;
        grid-column: 6/span 7;
        order: 2;

        overflow: hidden;
        border-radius: 1.5rem;

        img {
            min-width: 100%;
            max-height: 34rem;
            object-fit: cover;
        }
    }

}