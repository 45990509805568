// - Custom colors
// -----------------------------
$quality-control: true;
$dev: false;

// - Custom colors
// -----------------------------

// project colors
$black: #000;
$primary1: #2a292c; // default
$primary2: #2c80ff; // dark
$primary3: #0066ff; // dark-mode
$primary4: #d9e8ff; // light
$primary5: #eff5ff; // BG

// design
$yellow1: #ffb800; // default
$yellow2: #ed9d03; // dark
$yellow3: #ffd789; // darkmode
$yellow4: #fffbf2; // light
$yellow5: #ffefd0; // bg

// marketing
$pink1: #ef5da8; // default
$pink2: #ce3a86; // dark
$pink3: #f178b6; // darkmode
$pink4: #ffebf5; // light
$pink5: #f4c2dc; // bg

// strategie
$purple1: #9c41e3; // default
$purple2: #8121cc; // dark
$purple3: #ae6ae3; // darkmode
$purple4: #f9f2ff; // light
$purple5: #e1c2fa; // bg

// development
$green1: #31c1a7; // default
$green2: #0e9c82; // dark
$green3: #1fe9c4; // darkmode
$green4: #dafff5; // light
$green5: #b9f1e7; // bg

// grayscale
$gray1: #14142b; // title-archive
$gray2: #4e4b66; // body
$gray3: #929292; // label
$gray4: #a0a3bd; // placeholder
$gray5: #d9dbe9; // line
$gray6: #eff0f6; // input background
$gray7: #f7f7fc; // background
$gray8: #fcfcfc; // off-white

// red
$red1: #fd3b21;

// default
$black1: #2a292c;
$black2: #2c2b2e;
$black3: #09090a;

$red2: #bb1608;
$red3: #f37166;
$red4: #ffbfba;
$red5: #fff6f5;
$red6: #fee7e2;

$black: #000;
$white: #fff;
//$gray:#ccc;
//$gray5:#ccc;
$focus: orange;

// - Fonts
// -----------------------------
$fancy: 'Poppins', sans-serif;
$regular: 'Poppins', sans-serif;

// - Base settings
// -----------------------------
$base-background-color: $white;
$base-text-color: $primary1;
$base-fill-color: $primary1;
$base-link-color: $primary1;

$base-font-family: $regular;
$base-font-weight: 400;
$base-sd-font-size: 1.6rem;
$base-sd-line-height: 2.8rem;
$base-bd-font-size: 1.8rem;
$base-bd-line-height: 3.4rem;

// - Grid positioning (true enables compiling)
// -----------------------------

$small-offset: true;
$small-push: true;
$small-pull: true;

$medium-offset: true;
$medium-push: true;
$medium-pull: true;

$large: true;
$large-offset: true;
$large-push: true;
$large-pull: true;

$huge: false;
$huge-offset: false;
$huge-push: false;
$huge-pull: false;

$giant: false;
$colossal: false;

$col: false;

// - Mediaquery breakpoints
// -----------------------------

$screen-tiny: 480px;
$screen-small: 768px;
$screen-small-medium: 800px;
$screen-medium: 960px;
$screen-medium-large: 1060px;
$screen-large: 1200px;
$screen-huge: 1200px;
// $screen-giant:					1600px;
// $screen-colossal:				1800px;

$screen-mini-max: $screen-tiny - 1px;
$screen-tiny-max: $screen-small - 1px;
$screen-small-max: $screen-medium - 1px;
$screen-medium-max: $screen-large - 1px;
$screen-medium-large-max: $screen-large - 1px;
$screen-large-max: $screen-huge - 1px;
// $screen-huge-max:				$screen-giant - 1px;
// $screen-giant-max:				$screen-colossal - 1px;

// mostly for modals
$screen-bd-min-height: 601px;
$screen-bd-max-height: $screen-bd-min-height - 1px;

// 13 inch laptops
$screen-portable-width: 1280px;
$screen-portable-height: 800px;

// - Collumn settings
// -----------------------------

$base-grid-columns: 12;
$base-sd-grid-gutter-width: 20px;
$base-bd-grid-gutter-width: 20px;

// - Compile settings
// -----------------------------

$do-not-load: false;

// - Mobile menu
// -----------------------------

$menu-touch-overlay-has-child: false;
$menu-touch-overlay: true;
$menu-touch-regular: false;

// - Mixin
// -----------------------------

@mixin font($size, $line) {
  font-size: $size / 10 + rem;
  line-height: $line / 10 + rem;
}
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin bg-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
  $pval: $opacity * 100;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $pval ')';
  filter: alpha(opacity= $pval);
  zoom: 1;
}
@mixin transition($type, $speed, $ease) {
  -webkit-transition: $type $speed $ease;
  -moz-transition: $type $speed $ease;
  -o-transition: $type $speed $ease;
  transition: $type $speed $ease;
}

@mixin gradient($from, $to) {
  background-color: $from;
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from($from),
    to($to)
  );
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin no-styling {
  -webkit-appearance: none;
  -moz-appearance: none;
}
@mixin transform($transform) {
  -webkit-transform: rotate($transform);
  -moz-transform: rotate($transform);
  -ms-transform: rotate($transform);
  -o-transform: rotate($transform);
  transform: rotate($transform);
}
@mixin transformY($transform) {
  -webkit-transform: rotateY($transform);
  -moz-transform: rotateY($transform);
  -ms-transform: rotateY($transform);
  -o-transform: rotateY($transform);
  transform: rotateY($transform);
}
@mixin gradient-horizontal($from, $to) {
  background-color: $to;
  background-image: -moz-linear-gradient(left, $from, $to);
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from($from), to($to));
  background-image: -webkit-linear-gradient(left, $from, $to);
  background-image: -o-linear-gradient(left, $from, $to);
  background-image: linear-gradient(to right, $from, $to);
  background-repeat: repeat-x;
}
@mixin gradient-degree($deg, $from, $to) {
  background: $to;
  background: -moz-linear-gradient($deg + deg, $to 0%, $from 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, $to),
    color-stop(100%, $from)
  );
  background: -webkit-linear-gradient($deg + deg, $to 0%, $from 100%);
  background: -o-linear-gradient($deg + deg, $to 0%, $from 100%);
  background: -ms-linear-gradient($deg + deg, $to 0%, $from 100%);
  background: linear-gradient($deg + deg, $to 0%, $from 100%);
}
@mixin opacity($value) {
  opacity: $value;
  $opacity: $value * 100;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $opacity ')';
  filter: alpha(opacity= $opacity);
  zoom: 1;
}
@mixin box-shadows($shadow-1, $shadow-2, $shadow-3) {
  -webkit-box-shadow: $shadow-1, $shadow-2, $shadow-3;
  box-shadow: $shadow-1, $shadow-2, $shadow-3;
}
@mixin gradient-bottom($from, $to) {
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from($from),
    to($to)
  );
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}
@mixin text-opacity($color, $opacity) {
  color: rgba($color, $opacity);
  $pval: $opacity * 100;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $pval ')';
  filter: alpha(opacity= $pval);
  zoom: 1;
}
