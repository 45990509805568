.plugin-header {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 40px;
  margin-bottom: 70px;
  width: 100%;

  .showcase {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    align-items: center;
    width: 100%;
    gap: 30px;

    .showcase__content {
      grid-column: 1 / span 6;
      order: 1;
      grid-row: 1;

      .title {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.2rem;
        letter-spacing: 0.7px;
        word-wrap: break-word;
        margin-bottom: 5px;
        margin-top: 10px;
        color: #2c80ff;
        width: auto;
      }

      .heading {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
        width: 100%;
      }

      .heading_description {
        font-size: 2.4rem;
        line-height: 2.8rem;
        letter-spacing: 1px;
        margin-bottom: 10px;
        width: 100%;
      }

      .rating {
        gap: 10px;
        align-items: center;
        display: flex;
        margin-bottom: 20px;
      }

      .description {
        word-wrap: break-word;
        width: 100%;
      }

      .buttons {
        gap: 15px;
        flex-wrap: wrap;
        display: flex;
        margin-top: 25px;
        flex-direction: row;
        width: 100%;

        .button {
          fill: #fff;
          background-color: #2c80ff;
          border-color: #2c80ff;
          color: #fff;
          border-radius: 20px;
          font-size: 14px;
          gap: 10px;
          width: auto;
          align-items: center;
          display: flex;
          border-style: solid;
          border-width: 2px;
          box-shadow: 0 0 0 0 transparent;
          cursor: pointer;
          flex-wrap: nowrap;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }

    .showcase__figure {
      grid-column: 7 / span 7;
      order: 2;
      grid-row: 1;
    }
  }
}
