.case-customer-question {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .case-customer-question__content {
        grid-column: 2/span 10;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        align-items: center;

        .content-details {
            width: 100%;

            .details-title {
                width: 100%;
                font-size: 2rem;
                font-weight: 700;
            }

            .details-description {
                width: 100%;
                margin-top: 1rem;
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 2rem;
            }

        }

        .content-list {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            padding: 2rem;

            border-radius: 1.25rem;

            .list-item {
                position: relative;
                width: 100%;

                display: flex;
                align-items: center;

                .item-icon {
                    fill: #2c80ff;
                }

                .item-text {
                    margin-left: 1rem;
                    width: 100%;
                }
            }

        }
    }
}